import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { PaymentMethodsService } from 'api/paymentMethods';
import Form from 'components/common/Form/Form';
import FormInput from 'components/common/Form/Input';
import FormNumberField from 'components/common/Form/NumberField';
import CloseIcon from 'components/common/svg/close';
import { paymentResolver } from 'config/resolvers';
import useDialog from 'store/dialog';
import usePaymentMethods from 'store/paymentMethods';
import useTranslates from 'utils/translate';
import useScreen from 'hooks/useScreen';
import useToaster from 'hooks/useToaster';

import useCurrentUser from 'store/currentUser';
import { PaymentMethodTypes } from 'types';
import { RegistrationInitiator } from 'types/auth';
import PaymentService from './providers';
import sx from './styles';
import { convertAmount, convertTime } from './utils';
import useSelectedTheme from 'store/theme';

interface PaymentDialogProps {
  open: boolean;
  isDeposit?: boolean;
  onClose: () => void;
}

const PaymentDialog: FC<PaymentDialogProps> = ({ isDeposit, open, onClose }) => {
  const { control, getValues, handleSubmit, setValue, watch, setError } = useForm({
    resolver: paymentResolver,
  });
  const [amountFieldVisibility, setAmountFieldVisibility] = useState(false);
  const [dialog] = useDialog();
  const { isMobile } = useScreen();
  const [currentUser] = useCurrentUser();
  const notify = useToaster();
  const [{ isDark }] = useSelectedTheme();
  const [loading, setLoading] = useState(false);
  const { translate } = useTranslates();
  const [isProviderAllowingAction, setIsProviderAllowingAction] = useState(false);
  const [actionButtonVisibility, setActionButtonVisibility] = useState(true);

  const [{ methods, status }, paymentMethodActions] = usePaymentMethods();
  const [paymentMethodIndex, setPaymentMethodIndex] = useState<number | null>(null);

  const [isMobileViewToggledToDetails, setIsMobileViewToggledToDetails] = useState(false);
  const amount = watch('amount');
  const isAgentPlayer = currentUser.data.registrationInitiator === RegistrationInitiator.AGENT;

  useEffect(() => {
    !isAgentPlayer && paymentMethodActions.read(isDeposit ? 'deposit' : 'withdraw');
  }, [dialog]);

  useEffect(() => {
    if (methods.length) {
      setPaymentMethodIndex(0);
    }
  }, [methods]);

  const handlePayment = async () => {
    setLoading(true);
    const data = getValues();
    const action = isDeposit ? PaymentMethodsService.deposit : PaymentMethodsService.withdraw;

    const [successMessage, errorMessage] = isDeposit
      ? ['Deposit request has been sent successfully', 'Deposit request has been failed']
      : ['Withdrawal request has been sent successfully', 'Withdrawal request has been failed'];

    try {
      const response = await action({
        ...data,
        sourceId: methods[paymentMethodIndex].method.id,
        amount: Number(data.amount),
      });
      notify({
        ...(!response && { type: 'error' }),
        message: response ? successMessage : errorMessage,
      });
      response && onClose();
    } catch {
      notify({
        type: 'error',
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const selectedMethod = methods[paymentMethodIndex];

  useEffect(() => {
    setValue('amount', '');
  }, [paymentMethodIndex, setValue]);

  const formFields = (
    <Form onSubmit={handleSubmit(handlePayment)} key={String(paymentMethodIndex)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {amountFieldVisibility && (
            <FormNumberField
              maskFormat
              label={translate('payment_amount')}
              name="amount"
              placeholder={translate('payment_amount')}
              control={control}
            />
          )}
        </Grid>
        {(selectedMethod?.meta?.properties || [])
          .filter((property) => (isDeposit ? property.type === 'DEPOSIT' : property.type === 'WITHDRAW'))
          .map((property) => (
            <Grid item xs={12} key={property.key}>
              <FormInput
                label={translate(property.name)}
                type={property.type}
                name={property.key}
                required={property.isRequired}
                control={control}
              />
            </Grid>
          ))}
        <PaymentService
          paymentMethod={selectedMethod}
          watch={watch}
          control={control}
          setError={setError}
          getValues={getValues}
          setValue={setValue}
          isDeposit={dialog === 'deposit'}
          onClose={onClose}
          changeAmountFieldVisibility={setAmountFieldVisibility}
          changeActionButtonVisibility={setActionButtonVisibility}
          changeActionButtonAvailability={setIsProviderAllowingAction}
        />
        {actionButtonVisibility && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <LoadingButton
              loading={loading}
              disabled={!amount || !isProviderAllowingAction}
              variant="contained"
              color="success"
              sx={{ mt: 3 }}
              type="submit"
            >
              {translate(isDeposit ? 'deposit_now' : 'withdraw')}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Form>
  );

  const methodsContent = (
    <Stack px={isMobile ? 0 : 3} py={isMobile ? 0 : 3} gap={2} alignItems="center">
      {methods
        .filter(({ method }) =>
          isDeposit ? method.type !== PaymentMethodTypes.withdraw : method.type !== PaymentMethodTypes.deposit
        )
        .map((method, index) => (
          <Button
            key={index}
            onClick={() => {
              if (isMobile) {
                setIsMobileViewToggledToDetails(true);
              }
              setPaymentMethodIndex(index);
            }}
            sx={sx.paymentMethod({ selected: paymentMethodIndex === index })}
          >
            <Box display="flex" gap="16px">
              {(!!method.iconDark || !!method.iconLight) && <img style={{ height: 48, maxWidth: 70 }} src={isDark ? method.iconDark || method.iconLight : method.iconLight || method.iconDark} />}
              <Box>
                <Typography fontSize={12} fontWeight={700} color="text.primary">
                  {method.title || method.method.name}
                </Typography>
                <Stack direction="row" alignItems="center" divider={<Box component="em" sx={sx.paymentMethodDivider} />}>
                  <Typography sx={sx.paymentMethodText}>
                    <span>{translate(isDeposit ? 'min_deposit_amount' : 'min_withdraw_amount')}</span>
                    <b>{convertAmount(isDeposit ? method.currencies[0].minDeposit : method.currencies[0].minWithdraw)}</b>
                  </Typography>
                  <Typography sx={sx.paymentMethodText}>
                    <span>{translate(isDeposit ? 'max_deposit_amount' : 'max_withdraw_amount')}</span>
                    <b>{convertAmount(isDeposit ? method.currencies[0].maxDeposit : method.currencies[0].maxWithdraw)}</b>
                  </Typography>
                  <Typography sx={sx.paymentMethodText}>
                    <span>{translate(isDeposit ? 'deposit_execution_time' : 'withdraw_execution_time')}</span>
                    <b>{convertTime(method.executionTime)}</b>
                  </Typography>
                </Stack>
              </Box>
            </Box>
            
          </Button>
        ))}
    </Stack>
  );

  const isNotLoadedYet = status === 'loading' || status === 'idle';

  const noAvailableMethods = !isNotLoadedYet && !methods.length;

  return (
    <Dialog
      keepMounted
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 900, minHeight: 600 },
      }}
    >
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate(isDeposit ? 'deposit' : 'withdraw')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {isAgentPlayer && (
        <Box width="100%" height="100%" textAlign="center" flexGrow={1} alignContent="center">
          {translate('contact_your_agent')}
        </Box>
      )}
      {!isAgentPlayer && isNotLoadedYet && (
        <Box width="100%" height="100%" textAlign="center" flexGrow={1} alignContent="center">
          <CircularProgress />
        </Box>
      )}
      {!isAgentPlayer && noAvailableMethods && (
        <Box width="100%" height="100%" textAlign="center" flexGrow={1} alignContent="center">
          <Typography>{translate('no_payment_methods')}</Typography>
        </Box>
      )}
      {!isAgentPlayer && !isNotLoadedYet && !noAvailableMethods && (
        <Box
          display="flex"
          bgcolor="background.paper"
          height={isMobile ? '80vh' : '800px'}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          {isMobile ? (
            <Box
              p={2}
              height="100%"
              style={{
                overflowY: 'auto',
              }}
            >
              <Accordion
                expanded={!isMobileViewToggledToDetails}
                onChange={() => {
                  setIsMobileViewToggledToDetails((prev) => !prev);
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <Box display="flex" alignItems="center" color="text.primary" justifyContent="center">
                      <ExpandMoreIcon color="inherit" />
                    </Box>
                  }
                >
                  {translate('payment_methods')}
                </AccordionSummary>
                <AccordionDetails>{methodsContent}</AccordionDetails>
              </Accordion>
              {typeof paymentMethodIndex === 'number' && (
                <Accordion
                  expanded={isMobileViewToggledToDetails}
                  onChange={() => {
                    setIsMobileViewToggledToDetails((prev) => !prev);
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <Box display="flex" alignItems="center" color="text.primary" justifyContent="center">
                        <ExpandMoreIcon color="inherit" />
                      </Box>
                    }
                  >
                    Method Name ({selectedMethod?.title || selectedMethod?.method.name})
                  </AccordionSummary>
                  <AccordionDetails>{formFields}</AccordionDetails>
                </Accordion>
              )}
            </Box>
          ) : (
            <>
              <Box
                maxWidth="400px"
                flex={1}
                bgcolor="background.paper"
                style={{
                  overflowY: 'auto',
                }}
              >
                {methodsContent}
              </Box>
              <Box flex={1} bgcolor="secondary.light" p={3}>
                {formFields}
              </Box>
            </>
          )}
        </Box>
      )}
    </Dialog>
  );
};

export default PaymentDialog;
