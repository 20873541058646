import { Control, useController } from 'react-hook-form';

import { TextField, TextFieldProps } from '@mui/material';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import useScreen from 'hooks/useScreen';
import useTranslates from 'utils/translate';

import sx from './styles';

interface FormInputProps {
  name: string;
  label?: string;
  control: Control;
  success?: boolean;
  withPopper?: boolean;
}

const FormInput = ({
  name,
  label,
  control,
  success,
  placeholder,
  ...props
}: FormInputProps & TextFieldProps): JSX.Element => {
  const { isMobile } = useScreen();
  const { translate } = useTranslates();

  const {
    fieldState: { error },
    field: { ref, value = '', ...field },
  } = useController({
    name,
    control,
  });

  if (!name || !control) {
    return null;
  }

  const fieldPlaceholder = placeholder || label || '';

  return (
    <>
      <TextField
        {...props}
        {...field}
        value={value}
        error={!!error?.message}
        label={label}
        placeholder={fieldPlaceholder}
        ref={(el) => {
          ref(el);
        }}
        onBlur={e => {
          field.onBlur();
          props.onBlur?.(e);
        }}
        InputProps={{
          endAdornment: success && <CheckCircleOutlineIcon sx={sx.successIcon} />,
          sx: ({ palette }) => ({
            'input::placeholder': {
              color: !isMobile && palette.text.secondary,
            },
          }),
        }}
        helperText={!!error?.message && translate(error.message)}
      />
    </>
  );
};

FormInput.defaultProps = {
  fullWidth: true,
};

export default FormInput;
