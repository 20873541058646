import { useRef, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDebounce = (callback: (...args: any[]) => Promise<void>, wait = 0) => {
  const ref = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debouncedCallback = useCallback(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any[]) => {
      ref.current && clearTimeout(ref.current);
      ref.current = setTimeout(() => callback(...args), wait);
    },
    [callback, wait]
  );

  return debouncedCallback;
};

export default useDebounce;
