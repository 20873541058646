import { PaymentMethodsService } from "api/paymentMethods";
import useDebounce from "hooks/useDebounce";
import useMount from "hooks/useMount";
import { useCallback, useEffect, useRef, useState } from "react";
import useTranslates from "utils/translate";
import { ACCOUNT_NUMBER_LENGTH, BKASH_B, EMAIL_REGEXP, PAYMENT_INFO_DELAY } from "./constants";
import { Grid } from "@mui/material";
import FormInput from "components/common/Form/Input";
import { LoadingButton } from "@mui/lab";

const Withdraw = ({
  control,
  watch,
  setError,
  paymentMethod,
  changeActionButtonVisibility,
  changeAmountFieldVisibility,
}) => {
  const amount = watch('amount');
  const accountNumber = watch('account_number');
  const accountEmail = watch('account_email');
  const errorsCount = useRef(0);
  const [loading, setLoading] = useState(false);
  const { translate } = useTranslates();
  const [url, setUrl] = useState('');

  useMount(() => {
    changeAmountFieldVisibility(true);
    changeActionButtonVisibility(false);
  });

  const handler = useCallback(
    async (amount: number, sourceId: number, infoProperties: Record<string, string>) => {
      if (errorsCount.current > 0) return;

      const result = await PaymentMethodsService.getPaymentMethodExtraInfo<[{ key: string; value: string }]>({
        amount,
        sourceId,
        type: 'withdraw',
        infoProperties,
      });
      if (result[0]) {
        setUrl(result[0].value);
      }
      setLoading(false);
    },
    []
  );

  const debouncedPaymentMethodExtraInfo = useDebounce(handler, PAYMENT_INFO_DELAY);


  const isBkashB = paymentMethod.method.name === BKASH_B;
  const needsAccountEmail = isBkashB;

  useEffect(() => {
    if (amount && accountNumber && (accountEmail || !needsAccountEmail)) {
      setLoading(true);
      debouncedPaymentMethodExtraInfo(amount, paymentMethod.method.id, { accountNumber, accountEmail });
    }
  }, [amount, paymentMethod.method.id, debouncedPaymentMethodExtraInfo]);

  const onClick = () => {
    window.location.href = url;
  }
  
  const areAllFieldsFilled = Boolean(amount) && Boolean(url) && accountNumber && (!needsAccountEmail || Boolean(accountEmail));

  const onEmailFieldBlur = () => {
    const email = watch('account_email');
    if (EMAIL_REGEXP.test(email)) {

      setError('account_email', null)
      errorsCount.current -= 1;
    } else {
      setError('account_email', { message: translate('invalid_email') });
      errorsCount.current += 1;
    }
  }

  const onAccountNumberFieldBlur = () => {
    if (!isBkashB) return;

    const accountNumber = watch('account_number');
    if (accountNumber.length >= ACCOUNT_NUMBER_LENGTH) {
      setError('account_number', null)
      errorsCount.current -= 1;
    } else {
      setError('account_number', { message: translate('invalid_account_number') });
      errorsCount.current += 1;
    }
  }

  return (
    <>
    {
      needsAccountEmail && (
        <Grid item xs={24}>
          <FormInput
            onBlur={onEmailFieldBlur}
            label={translate('account_email')}
            name="account_email"
            required
            control={control}
          />
        </Grid>
      )
    }
    <Grid item xs={24}>
      <FormInput
        required
        control={control}
        onBlur={onAccountNumberFieldBlur}
        name="account_number"
        label={translate('account_number')}
      />
    </Grid>
    <Grid item xs={12}>
      <LoadingButton
        disabled={!areAllFieldsFilled}
        variant="contained"
        color="success"
        loading={loading}
        onClick={onClick}
        sx={{ mt: 3 }}
      >
        {translate('submit_deposit')}
      </LoadingButton>
    </Grid>
  </>
  );
}

export default Withdraw;
