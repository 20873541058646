import { PaymentMethodsService } from "api/paymentMethods";
import useDebounce from "hooks/useDebounce";
import useMount from "hooks/useMount";
import { useCallback, useEffect, useState } from "react";
import { BKASH_B, PAYMENT_INFO_DELAY } from "./constants";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useTranslates from 'utils/translate';
import FormInput from "components/common/Form/Input";

const Deposit = ({
  watch,
  control,
  paymentMethod,
  changeAmountFieldVisibility,
  changeActionButtonVisibility,
}) => {
  const amount = watch('amount');
  const accountNumber = watch('account_number');
  const { translate } = useTranslates()
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useMount(() => {
    changeAmountFieldVisibility(true);
    changeActionButtonVisibility(false);
  });

  const handler = useCallback(
    async (amount: number, sourceId: number, accountNumber?: string) => {
      const result = await PaymentMethodsService.getPaymentMethodExtraInfo<[{ key: string; value: string }]>({
        amount,
        sourceId,
        type: 'deposit',
        ...(!!accountNumber && {
          infoProperties: {
            account_number: accountNumber,
          }
        })
      });
      if (result[0]) {
        setUrl(result[0].value);
      }
      setLoading(false);
    },
    []
  );

  const debouncedPaymentMethodExtraInfo = useDebounce(handler, PAYMENT_INFO_DELAY);
  const needsAccountNumber = paymentMethod.method.name === BKASH_B;

  useEffect(() => {
    if (amount && (accountNumber || !needsAccountNumber)) {
      setLoading(true);
      debouncedPaymentMethodExtraInfo(amount, paymentMethod.method.id, accountNumber);
    }
  }, [amount, paymentMethod.method.id, accountNumber, needsAccountNumber, debouncedPaymentMethodExtraInfo]);

  const onClick = () => {
    window.location.href = url;
  }
  
  const areAllFieldsFilled = Boolean(amount) && Boolean(url) && (!needsAccountNumber || Boolean(accountNumber));

  return (
    <>
      {
        needsAccountNumber && (
          <Grid item xs={24}>
            <FormInput label={translate('account_number')} name="account_number" required control={control} />
          </Grid>
        )
      }
      <Grid item xs={12}>
        <LoadingButton
          disabled={!areAllFieldsFilled}
          variant="contained"
          color="success"
          loading={loading}
          onClick={onClick}
          sx={{ mt: 3 }}
        >
          {translate('submit_deposit')}
        </LoadingButton>
      </Grid>
    </>
  );
}

export default Deposit;